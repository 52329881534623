import { NavLink } from "react-router-dom"
import { useContext, useState } from "react"
import styles from "./Header.module.css"
import Logo from "../Images/Header/Logo.png"
import { CategoryContext } from "../Context/CategoryContext"
import logoBurger from "../Images/Header/logo for burger.png"
import burger from "../Images/Header/burger.png"
import close from "../Images/Header/close.png"

function Header() {
  const { changeActivity } = useContext(CategoryContext)

  const [modal, setModal] = useState(false)

  const openModal = () => {
    setModal(true)
  }

  const closeModal = () => {
    setModal(false)
  }

  return (
    <div className={styles.Header}>
      <div className={styles.nav}>
        <img
          src={Logo}
          alt='Logo'
          className={styles.logotype}
        ></img>
        <NavLink
          className={styles.navlink}
          to='.'
        >
          Главная
        </NavLink>
        <NavLink
          className={styles.navlink}
          to='about'
          onClick={() => changeActivity(0)}
        >
          О заводе
        </NavLink>
        <NavLink
          className={styles.navlink}
          to='services'
        >
          Услуги
        </NavLink>
        <NavLink
          className={styles.navlink}
          to='production'
        >
          Продукция
        </NavLink>
        <NavLink
          className={styles.navlink}
          to='news'
        >
          Новости
        </NavLink>
        <NavLink
          className={styles.navlink}
          to='contacts'
        >
          Контакты
        </NavLink>
        {/* <NavLink
          className={styles.navlink}
          to='subsidiaries'
        >
          Дочерние общества <br></br>АО «Роскартография»
        </NavLink> */}
        <img
          src={burger}
          className={modal === false ? styles.burgerActive : styles.burger}
          onClick={openModal}
        />
        <div className={modal === true ? styles.modalActive : styles.modal}>
          <img
            src={close}
            className={styles.close}
            onClick={closeModal}
          />
          <div className={styles.top}>
            <img
              src={logoBurger}
              alt='burger'
              className={styles.logoBurger}
            />
            <h5>
              Филиал ППК "Роскадастр" "Экспериментальный
              оптико-механический завод"
            </h5>
          </div>
          <NavLink to='.'>Главная</NavLink>
          <NavLink to='about'>О заводе</NavLink>
          <NavLink to='services'>Услуги</NavLink>
          <NavLink to='production'>Продукция</NavLink>
          <NavLink to='news'>Новости</NavLink>
          <NavLink to='contacts'>Контакты</NavLink>
          {/* <NavLink to='subsidiaries'>
            Дочерние общества АО «Роскартография»{" "}
          </NavLink> */}
        </div>
      </div>
    </div>
  )
}

export default Header
