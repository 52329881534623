import gnss from "../Images/Product/one.png"
import scaner from "../Images/Product/two.png"
import stereo from "../Images/Product/three.png"

const products = [
  {
    id: 1,
    title: "ГНСС приёмник БАЗИС-3",
    img: gnss,
    description:
      "Новый отечественный, мощный и надежный приемник для высокоточных навигационных систем, основан на 874-канальном чипе, оснащен встроенной картой 4G / LTE / 3G, защищенными, доступными картами microSD и microSIM и поддерживает технологию “IMU”.",
    other:
      "ФГУП ВНИИФТРИ использует приёмник ГНСС БАЗИС-3 в качестве эталонного приемника",
    extra: (
      <div>
        <p>
          Приемник БАЗИС-3 может работать как приемник для последующей
          обработки, как постоянно работающая опорная станция (CORS) или
          портативная базовая станция для кинематических приложений реального
          времени (RTK), а также как научная станция, собирающая информацию для
          отдельных исследований, таких как мониторинг ионосферы и тому
          подобное. Одним словом, он включает в себя все необходимое для
          выполнения высокоэффективной работы.
        </p>
        <p>Число каналов: 874</p>
        <ul>
          <label>Спутниковые системы:</label>
          <li>GPS L1, L1C, L2C, L5</li>
          <li>GLONASS L1OF, L2OF, L3OC</li>
          <li>Galileo E1, E5a, E5b, E6</li>
          <li>QZSS L1 C/A, L2C, L5</li>
          <li>BeiDou B1, B2, B3</li>
          <li>SBAS L1, L5</li>
          <li>IRNSS L5</li>
        </ul>
        <p>
          Проверенная технология отслеживания спутников с малым углом
          возвышения.
        </p>
        <p>Надежность инициализации более 99,9%</p>
        <p>Датчик наклона</p>
        <p>Встроенный электронный уровень с диапазоном работы не менее 30°</p>
      </div>
    ),
    specifications: (
      <div>
        <table>
          <thead>
            <tr>
              <th colspan='2'>Технические характеристики:</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                colspan='2'
                id='dop'
              >
                <b>Точность измерений</b>
              </td>
            </tr>
            <tr>
              <td>Статика и Быстрая статика:</td>
              <td>- В плане: 2,5 мм + 0,4 мм/км</td>
            </tr>
            <tr>
              <td>Кинематика с постобработкой и RTK:</td>
              <td>
                - В плане: 5 мм + 0,5 мм/км<br></br>- По высоте: 8 мм + 0,8
                мм/км
              </td>
            </tr>
            <tr>
              <td>Дифференциальные кодовые измерения (DGPS):</td>
              <td>
                - В плане: 25 см + 1 мм/км<br></br>- По высоте: 50 см + 1 мм/км{" "}
              </td>
            </tr>
            <tr>
              <td>Автономные измерения:</td>
              <td>
                - В плане: 1 м<br></br>- По высоте: 1,5 м
              </td>
            </tr>
            <tr>
              <td
                colspan='2'
                id='dop'
              >
                <b>Панель управления и индикация</b>
              </td>
            </tr>
            <tr>
              <td>Четыре клавиши на панели управления</td>
              <td>
                - Включение/отключение питания<br></br>- Включение/отключение
                bluetooth <br></br>- Включение/отключение wi-fi <br></br>-
                Включение/отключение записи файла статических измерений<br></br>
              </td>
            </tr>
            <tr>
              <td
                colspan='2'
                id='dop'
              >
                <b>Связь и хранение данных</b>
              </td>
            </tr>
            <tr>
              <td>Коммуникационные возможности:</td>
              <td>
                - 1 порт USB <br></br>- 2 последовательных порта RS-232{" "}
                <br></br>- Беспроводные подключения по Wi-Fi, Bluetooth и NFC{" "}
                <br></br>- 1 слот для SIM карты
              </td>
            </tr>
            <tr>
              <td>Встроенный приемопередающий 4G модем</td>
              <td>
                - Прием и передача дифференциальных поправок: LTE, HSPA+, HSDPA,
                HSUPA, WCDMA, GSM, GPRS, EDGE, LTE, EVDO, 1xRTT CDMA
              </td>
            </tr>
            <tr>
              <td>Встроенный радиомодем</td>
              <td>
                - UHF 406-470 MHz<br></br>- ISM 902-928/868-870 MHz
              </td>
            </tr>
            <tr>
              <td>Встроенная память</td>
              <td>16 ГБ</td>
            </tr>
            <tr>
              <td>Выходной сигнал</td>
              <td> 1Гц, 5Гц, 10 Гц, 20 Гц, 50Гц, 100Гц </td>
            </tr>
            <tr>
              <td>Ввод и вывод</td>
              <td> в CMR, RTCM 2.х, RTCM 3.1, 3.2</td>
            </tr>
            <tr>
              <td>Вывод координат</td>
              <td> в форматах NMEA-0183</td>
            </tr>
            <tr>
              <td>Запись сырых данных</td>
              <td>в форматах GNS, RINEX</td>
            </tr>
            <tr>
              <td
                colspan='2'
                id='dop'
              >
                <b>Аппаратные характеристики</b>
              </td>
            </tr>
            <tr>
              <td>Размеры (Ш х В х Д):</td>
              <td>182 х 96 х 78 мм</td>
            </tr>
            <tr>
              <td>Вес (с учетом аккумулятора):</td>
              <td>1,25 кг</td>
            </tr>
            <tr>
              <td>Рабочая температура:</td>
              <td>от - 45°С до +70°С</td>
            </tr>
            <tr>
              <td>Температура хранения:</td>
              <td>от -55°С до +85°С</td>
            </tr>
            <tr>
              <td>Пыле и влагозащищенность:</td>
              <td>IP 67</td>
            </tr>
            <tr>
              <td>Ударостойкость:</td>
              <td>выдерживает падение с высоты 3 метров</td>
            </tr>
            <tr>
              <td
                colspan='2'
                id='dop'
              >
                <b>Электрические характеристики</b>
              </td>
            </tr>
            <tr>
              <td>Встроенный литий-ионный аккумулятор:</td>
              <td>емкость 10 000 мАч</td>
            </tr>
            <tr>
              <td>Напряжение:</td>
              <td>не менее 7,2 ± 0,5 В</td>
            </tr>
            <tr>
              <td>Время непрерывной работы от одного аккумулятора:</td>
              <td>
                - 14 часов в режиме статики<br></br>- 10 часов в режиме RTK
                (GPRS, 3G, LTE)
              </td>
            </tr>
            <tr>
              <td
                colspan='2'
                id='dop'
              >
                <b>Функциональные возможности</b>
              </td>
            </tr>
            <tr>
              <td
                colspan='2'
                id='dop'
              >
                - BeiDou B1/B2/B3
              </td>
            </tr>
            <tr>
              <td
                colspan='2'
                id='dop'
              >
                - QZSS
              </td>
            </tr>
            <tr>
              <td
                colspan='2'
                id='dop'
              >
                - IRNSS
              </td>
            </tr>
            <tr>
              <td
                colspan='2'
                id='dop'
              >
                - Inertial Measurement Unit (IMU)
              </td>
            </tr>
            <tr>
              <td>Защита от спуфинга:</td>
              <td>
                Определение ложных GNSS сигналов, методом анализа дублирующих
                сигналов с одинаковым PRN
              </td>
            </tr>
            <tr>
              <td>Real Time Postprocessed Kinematics (RTPK):</td>
              <td>
                {" "}
                Возможность получать координаты с точностью метода RTK даже при
                полном потере фиксированного решения
              </td>
            </tr>
            <tr>
              <td>Подавление внутриполосных помех:</td>
              <td>
                Для защиты от сигналов, попадающих в полосу ГНСС-сигнала,
                предусмотрены пять сложных адаптивных фильтра в пяти различных
                диапазонах сигнала, способных подавлять помехи до 60 дБ
              </td>
            </tr>
            <tr>
              <td>Динамическая калибровка ГЛОНАСС 2 мм:</td>
              <td>
                Непрерывная и динамическая 0,2 мм калибровка межканальных
                смещений ГЛОНАСС
              </td>
            </tr>
            <tr>
              <td
                colspan='2'
                id='dop'
              >
                - NTRIP Caster
              </td>
            </tr>
            <tr>
              <td>Анализатор спектров:</td>
              <td>
                Возможность документального сохранения и анализа результатов
                помеховой обстановки на различных частотах (получение графиков
                зависимости уровня помех от времени на различных частотах)
              </td>
            </tr>
            <tr>
              <td
                colspan='2'
                id='dop'
              >
                - Частота обновления RTK 100Hz
              </td>
            </tr>
            <tr>
              <td
                colspan='2'
                id='dop'
              >
                - Скорость RTK 100 Hz
              </td>
            </tr>
            <tr>
              <td
                colspan='2'
                id='dop'
              >
                - Разъем для подключения внешней антенны
              </td>
            </tr>
            <tr>
              <td
                colspan='2'
                id='dop'
              >
                - JMT
              </td>
            </tr>
            <tr>
              <td>Heading Determination:</td>
              <td>
                Опция определения направления движения позволяет рассчитать
                направление и угол наклона для RTK-ровера. Порты приемника
                должны быть соединены кабелем. Первый приемник должен иметь
                опцию RTK, а второй должен иметь как RTK, так и опцию
                определения направления движения. Для двухантенных приемников
                эта опция покрывает работу в режиме RTK для первой антенны, а
                также определение направления движения и уклон
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    ),
  },
  {
    id: 2,
    title: "3D лазерный сканер Surphaser 100HSX",
    img: scaner,
    description:
      "Линейка сканеров Surphaser, известных своей непревзойденной точностью и качеством сканирования, включает модели с малым и средним радиусом действия, что идеально для обратного проектирования, метрологии, фиксации исторического наследия, архитектуры, судебной экспертизы.",
    other: "",
    extra: (
      <div>
        <ul>
          <li>
            Сканер с субмиллиметровой точностью и частотой сканирования до 1,2
            миллиона точек в секунду и диапазоном сканирования от 1 до 120м
          </li>
          <li>
            Разработан для применения как в промышленной среде, так и на
            открытой местности
          </li>
          <li>
            Программное обеспечение позволяет экспортировать «чистые» и точные
            наборы данных в PolyWorks®, RapidForm®, Geomagic®, Cyclone®,
            RealWorks® и другие прикладные системы
          </li>
          <li>
            Мобильный и удобный в эксплуатации. Предусмотрена переносная сумка,
            размер одобрен для сдачи в багаж в большинстве авиакомпаний
          </li>
          <li>Дополнительный встроенный контроллер и адаптер аккумулятора</li>
        </ul>
        <p>
          ОЕМ-продукты, построенные на базе технологии Surphaser — это блок
          измерения дальности и ПО. Варианты ПО включают все необходимое для
          быстрого изготовления заказных систем лазерного 3D сканирования для
          разнообразных областей применения.
        </p>
      </div>
    ),
    specifications: (
      <div>
        <table>
          <thead>
            <tr>
              <th colspan='2'>Технические характеристики:</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Тип сканера</td>
              <td>
                Фазовый сдвиг, полусферический сканер с областью обзора 360° на
                270°
              </td>
            </tr>
            <tr>
              <td>Метод измерения дальности:</td>
              <td>Фазовый сдвиг</td>
            </tr>
            <tr>
              <td>Длина волны лазера</td>
              <td>685 нм (красный)</td>
            </tr>
            <tr>
              <td>Тип лазера</td>
              <td>CW (непрерывный лазер)</td>
            </tr>
            <tr>
              <td>Мощность лазера</td>
              <td>15 мВт</td>
            </tr>
            <tr>
              <td>Класс лазера: (IEC EN60825-1:2007)</td>
              <td>Класс 3R</td>
            </tr>
            <tr>
              <td>Скорость сканирования (точек в секунду)</td>
              <td>от 208,000 до 1,200,000</td>
            </tr>
            <tr>
              <td>Разрешающая способность по дальности (мм)</td>
              <td>0,001</td>
            </tr>
            <tr>
              <td>Угловое разрешение по вертикали</td>
              <td>1 arc sec</td>
            </tr>
            <tr>
              <td>Угловое разрешение по горизонтали</td>
              <td>1 arc sec</td>
            </tr>
            <tr>
              <td>Мин. вертикальная плотность точек (точек/градус)</td>
              <td>24</td>
            </tr>
            <tr>
              <td>Мин. горизонтальная плотность точек (точек/градус)</td>
              <td>10</td>
            </tr>
            <tr>
              <td>Макс. вертикальная плотность точек (точек/градус)</td>
              <td>90</td>
            </tr>
            <tr>
              <td>Макс. горизонтальная плотность точек (точек/градус)</td>
              <td>90</td>
            </tr>
            <tr>
              <td>Время сканирования (мин. при плотности 7200х7200)</td>
              <td>4,5</td>
            </tr>
            <tr>
              <td>Горизонтальная зона обзора (максимум)</td>
              <td>360°</td>
            </tr>
            <tr>
              <td>Вертикальная зона обзора (максимум)</td>
              <td>270°</td>
            </tr>
            <tr>
              <td>Вес (кг)</td>
              <td>11</td>
            </tr>
            <tr>
              <td>Размеры (дшв)</td>
              <td>381 мм х 120 мм х 219 мм</td>
            </tr>
            <tr>
              <td>Данные окружающей среды</td>
              <td>
                Диапазон рабочих температур: от 5 до 45 С, без конденсации.
              </td>
            </tr>
            <tr>
              <td>Электропитание</td>
              <td>
                14-24В пост. тока, 45 Вт (без встроенного контроллера)<br></br>
                14-24В пост. тока, 55 Вт (со встроенным контроллером)
              </td>
            </tr>
            <tr>
              <td>Cтандартные принадлежности</td>
              <td>
                Транспортировочный контейнер <br></br>
                Кабель USB 2,0<br></br>
                Сетевой адаптер, 110/240 пер. тока, 19-24В пост. тока, 3,5А
                <br></br>
                Переходник для крепления сканера к штативу<br></br>
                Годовая гарантия и контракт на сопровождение
              </td>
            </tr>
            <tr>
              <td>Дополнительные принадлежности</td>
              <td>
                SMR совместимые черно-белые мишени с контейнером<br></br>
                Датчик угла наклона<br></br>
                Встроенный контроллер для управления сканированием и сбором
                данных без внешнего компьютера<br></br>
                Литиево-ионный аккумулятор 14В, 90 Вт-ч, вес 1кг<br></br>
                (рассчитан на постоянную работу от 1,5 до 2 часов)<br></br>
                Сетевое зарядное устройство<br></br>
                Штатив<br></br>
                Транспортный кейс для сканера (размер, одобренный для сдачи в
                багаж в большинстве авиакомпаний)
              </td>
            </tr>
            <tr>
              <td>Требования к Пк</td>
              <td>
                Минимальная конфигурация:<br></br>
                Процессор: 1,8 ГГц или больше, совместимый c процессором Pentium
                <br></br>
                Память системы (RAM) 1 ГБ или больше, рекомендовано 2 ГБ
                <br></br>
                ОС: Windows XP , Windows Vista, Windows 7, Windows 8,
                разрядность 32 и 64 бит<br></br>
                Порт USB 2,0
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    ),
  },
  {
    id: 3,
    title: "Стереомонитор ЭОМЗ",
    img: stereo,
    description:
      "«Экспериментальный оптико-механический завод» разработал и приступил к выпуску стереомониторов ЭОМЗ с диагональю 27” и 24”. Стереомониторы ЭОМЗ предназначены для стереоотображения объемной (3D) информации, поступающей с персонального компьютера или видеокамер. Принцип их действия основан на совмещении ортогонально-поляризованных изображений 2-х жидкокристаллических дисплеев с помощью полупрозрачного зеркала и последующей сепарации левой-правой половины стереопары через пассивные поляризационные очки.",
    specifications: (
      <div>
        <table>
          <thead>
            <tr>
              <th colspan='2'>Технические характеристики:</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Размер экрана</td>
              <td>27” / 24”</td>
            </tr>
            <tr>
              <td>Тип матрицы</td>
              <td>TN</td>
            </tr>
            <tr>
              <td>Разрешение</td>
              <td>1920х1080 точек</td>
            </tr>
            <tr>
              <td>Размер пикселя</td>
              <td>0,311 / 0,276 мм</td>
            </tr>
            <tr>
              <td>Контрастность</td>
              <td>1000:1 / 100000000:1</td>
            </tr>
            <tr>
              <td>Яркость</td>
              <td>300 / 350 кд/м2</td>
            </tr>
            <tr>
              <td>Углы обзора (гор/верт)</td>
              <td>170/160 град</td>
            </tr>
            <tr>
              <td>Количество цветов</td>
              <td>16,7 млн</td>
            </tr>
            <tr>
              <td>Время отклика</td>
              <td>5 / 1 мс</td>
            </tr>
          </tbody>
        </table>
      </div>
    ),
  },
]

export default products
