import { NavLink } from "react-router-dom"
import styles from "./Footer.module.css"
import Logo from "../Images/Footer/Logo-color.png"
import { useContext } from "react"
import { CategoryContext } from "../Context/CategoryContext"

function Footer() {
  const { changeActivity } = useContext(CategoryContext)

  return (
    <div className={styles.footer}>
      <div className={styles.top}>
        <img
          src={Logo}
          alt='Logo'
        />
        <div className={styles.head}>
          <h4>Экспериментальный оптико-механический завод</h4>
          <p>г. Москва, Шелапутинский пер. д.6 стр.3, 109004</p>
        </div>
      </div>
      <div className={styles.main}>
        <div className={styles.column}>
          <NavLink
            className={styles.colHead}
            to='about'
            onClick={() => changeActivity(0)}
          >
            О заводе
          </NavLink>
          <NavLink
            className={styles.colText}
            to='about'
            onClick={() => changeActivity(0)}
          >
            История
          </NavLink>
          <NavLink
            className={styles.colText}
            to='about'
            onClick={() => changeActivity(1)}
          >
            Сервисный центр
          </NavLink>
          <NavLink
            className={styles.colText}
            to='about'
            onClick={() => changeActivity(2)}
          >
            Документы
          </NavLink>
          <NavLink
            className={styles.colText}
            to='about'
            onClick={() => changeActivity(3)}
          >
            Сертификаты
          </NavLink>
          <NavLink
            className={styles.colText}
            to='about'
            onClick={() => changeActivity(4)}
          >
            Вакансии
          </NavLink>
          <NavLink
            className={styles.colText}
            to='about'
            onClick={() => changeActivity(5)}
          >
            Закупки
          </NavLink>
        </div>
        <div className={styles.column}>
          <NavLink
            className={styles.colHead}
            to='services'
          >
            Услуги
          </NavLink>
          <NavLink
            className={styles.colText}
            to='service/1'
          >
            Сборочный участок
          </NavLink>
          <NavLink
            className={styles.colText}
            to='service/2'
          >
            Механический участок
          </NavLink>
        </div>
        <div className={styles.column}>
          <NavLink
            className={styles.colHead}
            to='production'
          >
            Продукция
          </NavLink>
          <NavLink
            className={styles.colText}
            to='product/1'
          >
            GNSS-приемники
          </NavLink>
          <NavLink
            className={styles.colText}
            to='product/2'
          >
            Лазерные сканеры
          </NavLink>
          <NavLink
            className={styles.colText}
            to='product/3'
          >
            Стереомонитор
          </NavLink>
        </div>
        <div className={styles.column}>
          <NavLink
            className={styles.colHead}
            to='news'
            onClick={() => changeActivity(0)}
          >
            Новости
          </NavLink>
          <NavLink
            className={styles.colText}
            to='news'
            onClick={() => changeActivity(0)}
          >
            Лента
          </NavLink>
          <NavLink
            className={styles.colText}
            to='news'
            onClick={() => changeActivity(1)}
          >
            Новости
          </NavLink>
          <NavLink
            className={styles.colText}
            to='news'
            onClick={() => changeActivity(2)}
          >
            Статьи
          </NavLink>
          <NavLink
            className={styles.colText}
            to='news'
            onClick={() => changeActivity(3)}
          >
            Фото
          </NavLink>
          <NavLink
            className={styles.colText}
            to='news'
            onClick={() => changeActivity(4)}
          >
            Видео
          </NavLink>
          <NavLink
            className={styles.colText}
            to='news'
            onClick={() => changeActivity(5)}
          >
            Презентации
          </NavLink>
          <NavLink
            className={styles.colText}
            to='news'
            onClick={() => changeActivity(6)}
          >
            Z-новости
          </NavLink>
        </div>
        <div className={styles.column}>
          <NavLink
            className={styles.colHead}
            to='contacts'
          >
            Контакты
          </NavLink>
          <NavLink className={styles.colText}>
            <h5>Адрес:</h5>
            <p>Шелапутинский пер. д.6 стр.3</p>
          </NavLink>
          <NavLink className={styles.colText}>
            <h5>Телефон:</h5>
            <p>+7 (495) 911-01-12</p>
            <p>+7 (495) 911-02-75</p>
            <p>+7 (495) 911-32-70</p>
          </NavLink>
          <NavLink className={styles.colText}>
            <h5>E-mail:</h5>
            <p>oaoeomz@gmail.com</p>
          </NavLink>
        </div>
      </div>
      <p className={styles.bottom}>© «ЭОМЗ». Политика конфиденциальности</p>
    </div>
  )
}

export default Footer
