import { createContext, useState } from "react"

export const CategoryContext = createContext({
  activity: 0,
  changeActivity: () => "",
})
export const CategoryState = ({ children }) => {
  const [activity, setActivity] = useState(0)
  return (
    <CategoryContext.Provider value={{ activity, changeActivity: setActivity }}>
      {children}
    </CategoryContext.Provider>
  )
}
