import { useContext, useState } from "react"
import styles from "./About.module.css"
import abouts from "../Data/abouts"
import { useEffect } from "react"
import { CategoryContext } from "../Context/CategoryContext"

function About() {
  const [data, setData] = useState(abouts[0])

  const { activity, changeActivity } = useContext(CategoryContext)

  useEffect(() => {
    setData(abouts[activity])
  }, [activity])

  return (
    <div className={styles.wrapper}>
      <div className={styles.menu}>
        <button
          className={
            activity === 0
              ? (styles.menuButton, styles.active)
              : styles.menuButton
          }
          onClick={() => changeActivity(0)}
        >
          История
        </button>
        <button
          className={
            activity === 1
              ? (styles.menuButton, styles.active)
              : styles.menuButton
          }
          onClick={() => changeActivity(1)}
        >
          Сервис
        </button>
        <button
          className={
            activity === 2
              ? (styles.menuButton, styles.active)
              : styles.menuButton
          }
          onClick={() => changeActivity(2)}
        >
          Документы
        </button>
        <button
          className={
            activity === 3
              ? (styles.menuButton, styles.active)
              : styles.menuButton
          }
          onClick={() => changeActivity(3)}
        >
          Сертификаты
        </button>
        <button
          className={
            activity === 4
              ? (styles.menuButton, styles.active)
              : styles.menuButton
          }
          onClick={() => changeActivity(4)}
        >
          Вакансии
        </button>
        <button
          className={
            activity === 5
              ? (styles.menuButton, styles.active)
              : styles.menuButton
          }
          onClick={() => changeActivity(5)}
        >
          Закупки
        </button>
      </div>
      <div className={styles.main}>
        <img src={data.img} />
        <div className={styles.info}>
          {data.text}
          <div className={styles.wrap}>{data.block}</div>
          <div className={styles.certs}>{data.certificats}</div>
        </div>
      </div>
    </div>
  )
}

export default About
