const photos = [
  {
    title: "Конференция «ЦИФРОВАЯ РЕАЛЬНОСТЬ» 2021",
    type: "09.09.2021",
    link: "https://roscartography.ru/media/photo/item/49",
    img: "https://roscartography.ru/autoresize/webp/upload/dynamic/2022-04/28/3111548675-567e5d34.jpg.webp",
  },
  {
    title: "Роскартография на MAKS-2021",
    type: "23.07.2021",
    link: "https://roscartography.ru/media/photo/item/36",
    img: "https://roscartography.ru/autoresize/webp/upload/dynamic/2022-04/28/2294522285-752badb5.jpg.webp",
  },
  {
    title: "Вести с полей",
    type: "13.06.2019",
    link: "https://roscartography.ru/media/photo/item/35",
    img: "https://roscartography.ru/autoresize/webp/upload/dynamic/2022-04/28/3633821301-2e09504d-2b85e28f.jpg.webp",
  },
  {
    title:
      "Празднование 85-летия со дня основания Омской картографической фабрики",
    type: "16.05.2019",
    link: "https://roscartography.ru/media/photo/item/34",
    img: "https://roscartography.ru/autoresize/webp/upload/dynamic/2022-04/28/4547556402-a54d4d75.jpg.webp",
  },
  {
    title: "Празднование 100-летнего юбилея отрасли геодезии и картографии",
    type: "22.03.2019",
    link: "https://roscartography.ru/media/photo/item/33",
    img: "https://roscartography.ru/autoresize/webp/upload/dynamic/2022-04/28/2756196247-1b4f7993.jpg.webp",
  },
  {
    title:
      "I Международная научно-практическая конференция «Геодезия, картография и цифровая реальность»",
    type: "21.03.2019",
    link: "https://roscartography.ru/media/photo/item/32",
    img: "https://roscartography.ru/autoresize/webp/upload/dynamic/2022-04/28/3927184253-64547159.jpg.webp",
  },
  {
    title: "Научно-технический совет АО «Роскартография» на тему «Умный город»",
    type: "14.03.2019",
    link: "https://roscartography.ru/media/photo/item/31",
    img: "https://roscartography.ru/autoresize/webp/upload/dynamic/2022-04/28/5162811210-c9507035.jpg.webp",
  },
  {
    title: "Совещание директоров дочерних обществ АО «Роскартография»",
    type: "13.12.2018",
    link: "https://roscartography.ru/media/photo/item/30",
    img: "https://roscartography.ru/autoresize/webp/upload/dynamic/2022-04/28/4974521751-bc7869a5.jpg.webp",
  },
]

export default photos
