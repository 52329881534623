import { NavLink } from "react-router-dom"
import styles from "./previousBtn.module.css"
import arrow from "../Images/Arrow.png"

function previousBtn() {
  return (
    <NavLink
      to='../services'
      className={styles.btn}
    >
      <img src={arrow} />
      <button>Назад</button>
    </NavLink>
  )
}

export default previousBtn
