import { NavLink } from "react-router-dom"
import styles from "./Services.module.css"
import sbor from "../Images/Services/sbor.jpg"
import mech from "../Images/Services/mech.jpg"
import line from "../Images/Services/line.png"

function Services() {
  return (
    <div className={styles.wrapper}>
      <h1>Услуги</h1>
      <img
        src={line}
        className={styles.line}
      />
      <NavLink to='../service/1'>
        <div className={styles.card}>
          <img src={sbor} />
          <p>Сборочный участок</p>
        </div>
      </NavLink>
      <NavLink to='../service/2'>
        <div className={styles.card}>
          <img src={mech} />
          <p>Механический участок</p>
        </div>
      </NavLink>
    </div>
  )
}

export default Services
