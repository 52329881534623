import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { NavLink } from "react-router-dom"
import { Slide } from "react-slideshow-image"
import styles from "./Main.module.css"
import "react-slideshow-image/dist/styles.css"
import productBasis from "../Images/Main/product_1.png"
import productSurphaser from "../Images/Main/product_2.png"
import news from "../Data/news"
import necro from "../Images/News/Necro.png"
import doc from "./Docs/1_ПОЛОЖЕНИЕ_О_ЗАКУПКЕ.docx"

function Main() {
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.head}>
          <h2>
            Филиал ППК "Роскадастр" "Экспериментальный
            оптико-механический завод"
          </h2>
          <p>
            Основной деятельностью Филиал ППК "Роскадастр" "Экспериментальный
            оптико-механический завод" является производство, обслуживание и
            ремонт ГНСС приемников, изготовление электронных тахеометров
            работающих в диапазоне от -40°C градусов до +50°C, а также
            тахеометров предназначенных для работы во взрывоопасной среде.
          </p>
          <NavLink to='about'>
            <button>Подробнее</button>
          </NavLink>
        </div>
      </div>
      <div className={styles.production}>
        <h1>Наша продукция</h1>
        <div className={styles.main}>
          <NavLink to='./Product/1'>
            <div className={styles.product}>
              <img
                src={productBasis}
                alt='БАЗИС-3'
                id='basis'
              />
              <p>ГНСС приемник БАЗИС-3</p>
            </div>
          </NavLink>
          <NavLink to='./Product/2'>
            <div className={styles.product}>
              <img
                src={productSurphaser}
                alt='Сканер Surphaser'
                id='scaner'
              />
              <p>3D лазерные сканеры Surphaser</p>
            </div>
          </NavLink>
        </div>
        <NavLink
          to='production'
          className={styles.link}
        >
          <button>Вся продукция</button>
        </NavLink>
      </div>
      {/* <div className={styles.news}>
        <h1>Новости</h1>
        <div className={styles.carousel}>
          <Slide autoplay={true}>
            <NavLink
              to={news[0].link}
              target='_blank'
            >
              <div className={styles.eachSlideEffect}>
                <img src={necro} />
                <p>{news[0].title}</p>
              </div>
            </NavLink>
            <NavLink
              to={news[1].link}
              target='_blank'
            >
              <div className={styles.eachSlideEffect}>
                <img src={news[1].img} />
                <p>{news[1].title}</p>
              </div>
            </NavLink>
            <NavLink
              to={news[2].link}
              target='_blank'
            >
              <div className={styles.eachSlideEffect}>
                <img src={news[2].img} />
                <p>{news[2].title}</p>
              </div>
            </NavLink>
          </Slide>
        </div>
        <NavLink
          to='news'
          className={styles.link}
        >
          <button>Все новости</button>
        </NavLink>
      </div> */}
      {/* <div className={styles.document}>
        <h3>Положение о закупках товаров, работ, услуг «ЭОМЗ»</h3>
        <p>
          Настоящее Положение регулирует отношения, связанные с проведением
          закупок для нужд Филиал ППК "Роскадастр" "Экспериментальный
          оптико-механический завод".
        </p>
        <a
          href={doc}
          download='ПОЛОЖЕНИЕ О ЗАКУПКЕ.docx'
          target='_blank'
          className={styles.link}
        >
          <button>Ознакомиться с положением</button>
        </a>
      </div> */}
    </>
  )
}

export default Main
