import { useParams } from "react-router-dom"
import products from "../Data/products"
import styles from "./Product.module.css"

function Product() {
  const productID = Number(useParams().id)

  const title = products[productID - 1].title
  const image = products[productID - 1].img
  const description = products[productID - 1].description
  const other = products[productID - 1].other
  const extra = products[productID - 1].extra
  const specifications = products[productID - 1].specifications

  return (
    <div className={styles.wrapper}>
      <h2>{title}</h2>
      <div className={styles.top}>
        <img src={image} />
        <div className={styles.description}>
          <h3>Описание:</h3>
          <p>{description}</p>
          <span className={styles.other}>{other}</span>
        </div>
      </div>
      <div className={styles.extra}>{extra}</div>
      <div className={styles.specifications}>{specifications}</div>
    </div>
  )
}

export default Product
