const news = [
  // {
  //   title: "Некролог Логвинова Дмитрия Валерьевича",
  //   type: "Новость • 22.08.2023",
  //   link: "https://roscartography.ru/media/news/item/97",
  //   img: "https://roscartography.ru/autoresize/webp/upload/dynamic/2023-08/22/1-d6730f1c.png.webp",
  // },
  // {
  //   title: "Великая Отечественная Война. Факты о геодезистах и картографах",
  //   type: "Новость • 09.05.2023",
  //   link: "https://roscartography.ru/media/news/item/94",
  //   img: "https://roscartography.ru/autoresize/webp/upload/dynamic/2023-05/10/4-6d4c1c39.jpg.webp",
  // },
  // {
  //   title: "Роскартография приступила к полевым работам 2023 года",
  //   type: "Новость • 25.04.2023",
  //   link: "https://roscartography.ru/media/news/item/93",
  //   img: "https://roscartography.ru/autoresize/webp/upload/dynamic/2023-05/10/v_rabote-378c020b.jpeg.webp",
  // },
  // {
  //   title:
  //     "Балт АГП начнет производство тактильных карт Калининградской области",
  //   type: "Новость • 17.04.2023",
  //   link: "https://roscartography.ru/media/news/item/92",
  //   img: "https://roscartography.ru/autoresize/webp/upload/dynamic/2023-04/25/photo_2023-04-25_091544-9966f621.jpeg.webp",
  // },
  // {
  //   title: "Роскартография, Ракурс и Роскосмос проведут ежегодную конференцию",
  //   type: "Новость • 04.04.2023",
  //   link: "https://roscartography.ru/media/news/item/91",
  //   img: "https://roscartography.ru/autoresize/webp/upload/dynamic/2023-04/25/2023-04-25_091305-51f72ef7.jpg.webp",
  // },
  // {
  //   title: "Роскартография запустила обновленный интернет-магазин",
  //   type: "Новость • 27.03.2023",
  //   link: "https://roscartography.ru/media/news/item/90",
  //   img: "https://roscartography.ru/upload/dynamic/2022-04/07/lazy-ea452b26.png",
  // },
  // {
  //   title:
  //     "День геодезии и картографии 2023: награждение сотрудников в честь праздника",
  //   type: "Новость • 13.03.2023",
  //   link: "https://roscartography.ru/media/news/item/89",
  //   img: "https://roscartography.ru/upload/dynamic/2022-04/07/lazy-ea452b26.png",
  // },
  // {
  //   title: "Министерство обороны России наградило сотрудников Роскартографии",
  //   type: "Новость • 12.02.2023",
  //   link: "https://roscartography.ru/media/news/item/88",
  //   img: "https://roscartography.ru/upload/dynamic/2022-04/07/lazy-ea452b26.png",
  // },
]

export default news
