import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import "./Fonts/Montserrat-Regular.ttf"
import { CategoryState } from "./Context/CategoryContext"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <CategoryState>
    <App />
  </CategoryState>
)
