import { NavLink } from "react-router-dom"
import history from "../Images/About/history.jpg"
import service from "../Images/About/service.jpg"
import documents from "../Images/About/documents.jpg"
import certificats from "../Images/About/certificats.jpg"
import jobs from "../Images/About/jobs.jpg"
import goods from "../Images/About/purchases.jpg"
import doc from "../Images/About/doc.png"
import cert1 from "../Images/About/certificat1.png"
import cert2 from "../Images/About/certificat2.png"
import cert3 from "../Images/About/certificat3.png"
import doc1 from "../Components/Docs/УСТАВ ППК Роскадастр.pdf"
import doc2 from "../Components/Docs/Положение о филиале ППК Роскадастр ЭОМЗ.pdf"
import doc3 from "../Components/Docs/1_ПОЛОЖЕНИЕ_О_ЗАКУПКЕ.docx"
import doc4 from "../Components/Docs/2. Приложение № 1 Положение о закупочной комиссии.docx"
import doc5 from "../Components/Docs/3. Приложение № 2 Положение о планировании.docx"
import doc6 from "../Components/Docs/3.1. Приложение № 1_2021.xlsx"
import doc7 from "../Components/Docs/4. Приложение № 3 Порядок проведения торгов.docx"
import doc8 from "../Components/Docs/5. Приложение № 4 Особенности выбора нескольких победителей.docx"
import doc9 from "../Components/Docs/6. Приложение № 5 Порядок проведения закупки у ЕП.DOCX"
import doc10 from "../Components/Docs/7. Приложение № 6 Особенности проведения закупок в бумажной форме.docx"
import doc11 from "../Components/Docs/8. Приложение № 7 Признание закупки несостоявшейся.docx"
import doc12 from "../Components/Docs/9. Приложение № 8 Особые закупочные ситуации.docx"
import doc13 from "../Components/Docs/10. Приложение № 9 Особенности проведения закупок у субъектов МСП.DOCX"
import doc14 from "../Components/Docs/11. Приложение № 10 Правила  оценки..docx"
import doc15 from "../Components/Docs/12. Приложение № 11 Методика НМЦ.DOCX"

const abouts = [
  {
    id: 1,
    img: history,
    text: (
      <div>
        <p>
          Завод вырос из мастерской по ремонту геодезических приборов,
          организованной в 1928 году в г. Москве. В 1930 г. мастерская была
          передана в ведение Главного Управления государственной съемки и
          картографии (ГУГСК НКВД) и переименована в завод «Аэрогеоприбор».
        </p>
        <p>
          Постановлением Коллегии ГУГК при Совете Министров СССР от 1946 года
          завод был размещен в здании Московского аэрогеодезического предприятия
          по адресу: Шелапутинский переулок дом 6. В последствии завод
          «Аэрогеоприбор» был переименован в ЭОМЗ ЦНИИГАиК; ЭОМЗ.
        </p>
        <p>
          Экспериментальный оптико-механический завод создавался для выпуска
          прецизионной геодезической, стереофотограмметрической и
          картографической техники.
        </p>
        <p>
          Заводом производились стереографы Дробышева СД, стереографы ЦНИИГАиКа
          СЦ-1, угломерные высокоточные комплекты УВК, УВК-М, приборы
          астрономического определения ПАО-1, астрономические комплексы АУ-01
          (выпускались с военной приемкой и входили в состав комплекса
          Тополь-М), маятниковые комплексы ПМП-М, ПДМ (выпускались с военной
          приемкой и поставлялись на подводные лодки для Министерства обороны),
          баллистические гравиметры ГБЛ, светодальномеры «Гранат» и другие.
        </p>
        <p>
          Всеми этими приборами завод обеспечивал все аэрогеодезические
          предприятия, входящих в геодезическую и картографическую отрасль.
        </p>
        <p>
          Весь технологический цикл по созданию и обновлению топографических
          карт производился приборами, выпускаемыми заводом.
        </p>
        <p>
          Кроме того, были созданы государственные геодезические сети всех
          классов, включая ФАГС, ВГС и СГС.
        </p>
        <p>
          В 1993 году было освоено производство стереоскопов SТ-4,
          предназначенных для трехмерного наблюдения стереопар аэрофотоснимков,
          оптико-механических узлов системы для дешифрирования снимков АРТ-2.
        </p>
        <p>
          Результатом подписания Соглашения между Leica Geosystems и ЭОМЗ стала
          организация производства ГЛОНАСС/GPS -приемников и тахеометров на
          заводе. 22 мая 2006 года состоялось открытие линии по производству
          электронных тахеометров TCR405power и ГЛОНАСС/GPS –приемников 1220 GG.
          Линия устанавливалась и была протестирована швейцарскими
          специалистами. Линия соответствует всем мировым стандартам.
        </p>
        <p>
          В 2006 году начался выпуск электронных тахеометров TCR802power, TS 02,
          TS 06, TРS 1200, ГЛОНАСС/GPS –приемников 1230 GG. На Экспериментальном
          оптико-механическом заводе открылся авторизованный сервисный центр по
          техническому обслуживанию тахеометров и приемников.{" "}
        </p>
        <p>
          С 2011 года началось совместное сотрудничество с американской
          компанией «Basis Software Inc» (США) по выпуску 3D лазерных сканеров
          SURPHASER. С 2011 года по 2022 год было выпущено порядка 500 штук 3D
          лазерных сканеров, из которых около 450 штук было поставлено на
          экспорт в США, остальные были реализованы на российском рынке.
        </p>
        <p>
          В 2012 году на заводе подготовлены рабочие места для сборки
          тахеометров GeoMax (Швейцария) серии ZOOM20 и ZOOM30.
        </p>
        <p>
          С 2015 года освоен выпуск взрывозащищенных тахеометров TS06-5 Ex. С
          2015 года по 2022 год завод выпустил около 200 штук взрывозащищенных
          тахеометров TS06-5 Ex.
        </p>
        <p>
          Экспериментальный оптико-механический завод являлся единственным в
          России выпускающим суперарктические версии тахеометров в диапазоне от
          -40°С до +50°С и взрывозащищенные тахеометры.
        </p>
        <p>
          Завод с 2015 года начал производство электронных тахеометров TS 06
          RUS, TS 09 RUS и спутниковых приемников GS 08 RUS, GS10 unlimited RUS,
          GS 14 unlimited RUS.
        </p>
        <p>
          С 2021 года Экспериментальный оптико-механический завод начал выпуск
          комплексов стереомониторов ЭОМЗ 27”. Заводом поставлено 46 комплектов
          стереомониторов ЭОМЗ 27”.
        </p>
        <p>
          С уходом иностранных компаний с российского рынка в 2022 году
          Экспериментальный оптико-механический завод продолжает осуществлять
          сервисное обслуживание (ремонт) всех вышеперечисленных приборов.
        </p>
        <p>
          В октябре 2023 года между филиалом «ЭОМЗ» АО «Роскартография» и
          компанией ООО «Геостройизыскания» подписан договор на выпуск
          арктической версии тахеометров VEGA NX42.
        </p>
        <p>
          В 2024 году проводятся испытания взрывозащищенных тахеометров
          компаниями ООО «ЕФТ Групп» и ООО «Геостройизыскания».
        </p>
        <p>
          Сотрудники завода, задействованные в производстве приборов, имеют
          соответствующие сертификаты. За заслуги в области приборостроения 6
          (шесть) сотрудников завода имеют почетное звание «Заслуженный работник
          геодезии и картографии Российской Федерации», многие награждены
          значками «Почетный геодезист» и «Отличник геодезии и картографии».
        </p>
      </div>
    ),
  },
  {
    id: 2,
    img: service,
    text: (
      <div>
        <p>
          "ЭОМЗ" является официально аккредитованным сервис партнером по TPS
        </p>
        <p>
          Ремонт тахеометров, одних из самых интеллектуальных устройств в ряду
          геодезического оборудования, представляет собой непростую задачу. Её
          решение можно доверить только профессионалам. Наиболее выгодным
          решением, которое позволит сэкономить сотни тысяч рублей на покупке
          нового прибора, является ремонт тахеометров с помощью специалистов,
          которые не только обладают необходимыми знаниями и многолетним опытом,
          методиками диагностики и контроля параметров, но и применяют
          соответствующее ремонтное и испытательное оборудование, оригинальные
          комплектующие для замены неисправных деталей. Наша компания выполняет
          ремонт тахеометров любой сложности быстро и качественно. Мы
          осуществляем ремонт тахеометров и спутниковых приемников Leica и
          GeoMax.
        </p>
        <p>
          Несмотря на улучшенную защиту и все методы предосторожности тахеометры
          также как и все приборы выходят из строя. Учитывая стоимость такого
          прибора, ремонт тахеометра и, тем более, профилактическое обслуживание
          будет намного более выгодным решением, чем замена устройства.
        </p>
        <p>
          Ремонт тахеометров, который осуществляет наша компания является
          трудоёмким, сложным и ответственным процессом, в регламент которого
          включены несколько десятков операций от самых простых процедур замены
          платы до дорогостоящих работ по замене корпуса телескопа или
          устранению рысканья. Поскольку современный тахеометр сочетает в себе
          функции дальномера, теодолита и микропроцессора, то и его ремонт
          включает в себя несколько основных позиций: ремонт дальномера и
          телескопа; ремонт теодолитной части прибора; прошивку программного
          обеспечения.
        </p>
        <p>
          Независимо от сложности поломки специалисты сервисного центра проведут
          тщательный анализ и диагностику повреждений и выполнят ремонт
          электронного тахеометра в короткие сроки и с неизменно высоким
          качеством
        </p>
        <p>
          Наш сервисный центр оснащён сертифицированным ремонтным оборудованием
          для ремонта тахеометров. По завершению работ специалисты сервисного
          центра выполнят обязательную проверку рабочих характеристик
          тахеометра, будет произведена его настройка, отладка, тестирование и
          обязательная поверка тахеометра. Мы не рекомендуем владельцам
          дорогостоящих тахеометров осуществлять кустарный ремонт тахеометров,
          поскольку это может привести к окончательной поломке прибора и
          неоправданной потере средств и времени.
        </p>
        <p>
          Наша компания осуществляет не только ремонт тахеометров, но и
          предлагает своим клиентам услуги по юстировке, поверке прибора с
          выдачей свидетельства, а также по диагностике тахеометра, которая
          проводится как плановая проверка или перед началом ремонта. В процессе
          диагностики прибора специалисты осуществляют проверку
          работоспособности всех систем тахеометра с описанием найденных
          неисправностей и выдачей рекомендаций по их устранению. Ремонт
          тахеометра в нашем сервисном центре – это выгодный способ продлить
          срок службы хорошему прибору.
        </p>
        <p>
          Авторизованный сервисный центр выполняет ремонт и техническое
          обслуживание тахеометров и спутниковых приемников.
        </p>
        <p>
          Тахеометры GeoMax серии ZOOM 20/30/35 Pro и спутниковые приемники
          Zenith25 ProДополнительная установка арктической версии Polar (до
          -35°С)
        </p>
        <p>
          Тахеометры: Leica серии TCR1200 и FlexLine TS06/09Дополнительная
          установка арктической версии SuperArctic (до-40°С)Спутниковые
          приемники GS08/10/14
        </p>
        <p>
          Предоставляем услуги по климатическим испытаниям приборной продукции.
        </p>
        <p>
          Технические характеристики климатической камеры: рабочий объем:
          2300х2000х1900мм. диапазон стабилизируемых температур: от -40 до +85°С
          точность поддержания температуры: ±1°С
        </p>
        <p>Гарантийное обслуживание всех приборов 1 год.</p>
        <p>тел: 8 (495) 911-14-63эл. почта: eomz-geo@yandex.ru</p>
      </div>
    ),
  },
  {
    id: 3,
    img: documents,
    text: "",
    block: (
      <>
        <a
          href={doc1}
          download='УСТАВ ППК Роскадастр'
          target='_blank'
        >
          <div>
            <img src={doc} />
            <p>УСТАВ ППК Роскадастр</p>
          </div>
        </a>
        <a
          href={doc2}
          download='Положение о филиале ППК Роскадастр'
          target='_blank'
        >
          <div>
            <img src={doc} />
            <p>Положение о филиале ППК Роскадастр</p>
          </div>
        </a>
      </>
    ),
  },
  {
    id: 4,
    img: certificats,
    text: "",
    certificats: (
      <>
        <NavLink>
          <div>
            <img src={cert1} />
            <p>Сертификат Basis Software</p>
          </div>
        </NavLink>
        <NavLink>
          <div>
            <img src={cert2} />
            <p>Сертификат GEOMAX</p>
          </div>
        </NavLink>
        <NavLink>
          <div>
            <img src={cert3} />
            <p>Сертификат на стереомонитор</p>
          </div>
        </NavLink>
      </>
    ),
  },
  {
    id: 5,
    img: jobs,
  },
  {
    id: 6,
    img: goods,
    text: "",
    block: <>{/* <p>Нет актуальных данных</p> */}</>,
  },
]

export default abouts
