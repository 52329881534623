import { useParams } from "react-router-dom"
import styles from "./Service.module.css"
import line from "../Images/Services/line.png"
import services from "../Data/services"
import PreviousBtn from "../UI/previousBtn"

function Service() {
  const serviceID = Number(useParams().id)

  const title = services[serviceID - 1].title
  const text = services[serviceID - 1].text

  return (
    <div className={styles.wrapper}>
      <PreviousBtn />
      <h1>{title}</h1>
      <img
        src={line}
        className={styles.line}
      />
      <div className={styles.text}>{text}</div>
    </div>
  )
}

export default Service
