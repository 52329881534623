import { NavLink } from "react-router-dom"
import styles from "./Production.module.css"
import line from "../Images/Services/line.png"
import basis from "../Images/Production/gnss.png"
import scaner from "../Images/Production/scaner.png"
import stereo from "../Images/Production/stereo.png"
// import Product from "./Product"
// import products from "../Data/products"

function Production() {
  return (
    <div className={styles.wrapper}>
      <h1>Продукция</h1>
      <img
        src={line}
        className={styles.line}
      />
      <div className={styles.card}>
        <NavLink
          to='../product/1'
          className={styles.link}
        >
          <img src={basis} />
          <p>Спутниковые GNSS-приемники</p>
        </NavLink>
      </div>
      <div className={styles.card}>
        <NavLink
          to='../product/2'
          className={styles.link}
        >
          <img src={scaner} />
          <p>3D лазерные сканеры Surphaser</p>
        </NavLink>
      </div>
      <div className={styles.card}>
        <NavLink
          to='../product/3'
          className={styles.link}
        >
          <img src={stereo} />
          <p>Стереомонитор ЭОМЗ</p>
        </NavLink>
      </div>
    </div>
  )
}

export default Production
