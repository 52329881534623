import { BrowserRouter, Routes, Route } from "react-router-dom"
import MainLayout from "./layout/MainLayout"
import "./App.css"
import Main from "./Components/Main"
import About from "./Components/About"
import Services from "./Components/Services"
import Production from "./Components/Production"
import News from "./Components/News"
import Contacts from "./Components/Contacts"
import Subsidaries from "./Components/Subsidiaries"
import Service from "./Components/Service"
import Product from "./Components/Product"
import ScrollToTop from "./Components/ScrollToTop"

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className='App'>
        <Routes>
          <Route
            path='/'
            element={<MainLayout />}
          >
            <Route
              index
              element={<Main />}
            ></Route>
            <Route
              path='about'
              element={<About />}
            ></Route>
            <Route
              path='services'
              element={<Services />}
            ></Route>
            <Route
              path='service/:id'
              element={<Service />}
            ></Route>
            <Route
              path='production'
              element={<Production />}
            ></Route>
            <Route
              path='product/:id'
              element={<Product />}
            ></Route>
            <Route
              path='news'
              element={<News />}
            ></Route>
            <Route
              path='contacts'
              element={<Contacts />}
            ></Route>
            <Route
              path='subsidiaries'
              element={<Subsidaries />}
            ></Route>
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  )
}

export default App
