const videos = [
  {
    title: "Корпоративный видеоролик Роскартографии",
    type: "15.03.2022",
    link: "https://roscartography.ru/media/video/item/42",
    img: "https://roscartography.ru/autoresize/webp/upload/dynamic/2022-06/14/Shop-interior-2_1-f1307f58.jpg.webp",
  },
  {
    title: "«Музыка Приволжья»",
    type: "27.12.2021",
    link: "https://roscartography.ru/media/video/item/25",
    img: "https://roscartography.ru/autoresize/webp/upload/dynamic/2022-06/14/UyMmo5pIxkAoARpB7tAgndVIpBzoCOGM-84446e45.png.webp",
  },
]

export default videos
